import styled from 'styled-components'
import { styles } from '../../assets/styles/globals'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)`
    color: ${styles.colors.bw9};
    text-decoration: none;
    cursor: pointer;
`

export default StyledLink
