import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import NewReleases from '@material-ui/icons/NewReleases'

import { getAllPatients } from '../services/patient'
import { styles } from '../assets/styles/globals'
import Container from './containers'
import PatientSearch from '../components/patient-search'
import { boxShadow } from '../assets/styles/style-utils'

const { arrayOf, func, shape } = PropTypes

const NavBar = styled.nav`
    background-color: ${styles.colors.white};
    ${boxShadow};
`

const NavWrap = Container.withComponent('div').extend`
    display: flex;
    justify-content: space-between;
    position: relative;
`

const NavList = styled.ul`
    height: ${styles.navHeight};
`

const NavListRight = NavList.extend`
    text-align: right;
`

const NavItem = styled.li`
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 0 1em;

    &:first-child {
        padding-left: 0;
    }
`

const PointerItem = NavItem.extend`
    cursor: pointer;
`

const NavLinkContent = styled.span`
    text-decoration: none;
    color: ${styles.baseFontColor};
    display: block;
    line-height: ${styles.navHeight};
    padding: 0 0.75em;
`

const NavContentWithBorder = NavLinkContent.extend`
    box-shadow: inset 0 0 0 0 ${styles.colors.blue};
    transition: box-shadow 0.1s ease-out;
    cursor: pointer;

    &:hover,
    &.active {
        box-shadow: inset 0 -0.28rem 0 0 ${styles.colors.blue};
    }
`

const NavLinkComponent = NavContentWithBorder.withComponent(styled(NavLink)``)

const NotificationsIcon = styled(NewReleases)`
    margin-left: 0.5rem;
    color: ${styles.colors.red};
`

class Navigation extends Component {
    static propTypes = {
        user: shape({}).isRequired,
        unAuthUser: func.isRequired,
        navItems: arrayOf(shape({})).isRequired,
        patients: arrayOf(shape({})).isRequired
    }

    constructor() {
        super()

        this.togglePatientSearch = this.togglePatientSearch.bind(this)
    }

    state = {
        patientSearchVisible: false
    }

    togglePatientSearch() {
        this.setState({
            patientSearchVisible: !this.state.patientSearchVisible
        })
    }

    fetchNewPatients = () => {
        getAllPatients(this.props.user)
    }

    render() {
        return (
            <NavBar>
                <NavWrap>
                    <NavList>
                        <NavItem key="jh">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 164 164"
                                role="img"
                                aria-labelledby="jh-title"
                                className="header-logo"
                                style={{
                                    fill: styles.colors.bw10,
                                    width: '5rem',
                                    height: '5rem'
                                }}
                            >
                                <title id="jh-title">Jawbone Health</title>
                                <path d="M40.2 42h9.6v58.2c0 10.3-4.6 17.4-26.3 26.9l-.4-3.1c16.1-10.4 17.1-12.3 17.1-22.5V42zM121.3 42v32.5H86.4V42h-9.6v72h9.6V80.5h34.9V114h9.5V42z" />
                            </svg>
                        </NavItem>
                        {this.props.navItems.map(item => (
                            <NavItem key={item.id}>
                                <NavLinkComponent
                                    to={item.href}
                                    exact={item.exact}
                                >
                                    {item.title}
                                    {item.id === 'dashboard' && this.props.user.notifications && <NotificationsIcon />}
                                </NavLinkComponent>
                            </NavItem>
                        ))}
                        <NavItem>
                            <NavContentWithBorder
                                onClick={this.togglePatientSearch}
                                data-cy="main-patient-link"
                            >
                                Patients
                            </NavContentWithBorder>
                        </NavItem>
                    </NavList>
                    <NavListRight>
                        <NavItem>
                            <NavLinkComponent to="/settings">
                                {this.props.user.name}
                            </NavLinkComponent>
                        </NavItem>
                        <PointerItem onClick={this.props.unAuthUser}>
                            <NavContentWithBorder data-cy="main-signout-link">
                                Sign out
                            </NavContentWithBorder>
                        </PointerItem>
                    </NavListRight>
                    <PatientSearch
                        patients={this.props.patients}
                        visible={this.state.patientSearchVisible}
                        closePatientSearch={this.togglePatientSearch}
                        fetchNewPatients={this.fetchNewPatients}
                    />
                </NavWrap>
            </NavBar>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Navigation)
