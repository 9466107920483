import React, { Fragment, Component } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { styles } from '../../assets/styles/globals'
import { propTypes, withFormsy } from 'formsy-react'
import Error from './../error'
import { cleanInputStyles } from '../../assets/styles/style-utils'

const InputError = Error.extend`
    margin-top: 1rem;
`

export const CleanInput = styled.input`
    ${cleanInputStyles};
`

export const StyledInput = CleanInput.extend`
    border: 1px solid
        ${props => (props.error ? styles.colors.red : styles.borderColor)};
`

class Input extends Component {
    static propTypes = {
        type: string.isRequired,
        name: string,
        className: string,
        ...propTypes
    }

    static defaultProps = {
        name: '',
        className: ''
    }

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
    }

    state = {
        allowError: false
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isFormSubmitted()) this.showError()
    }

    showError() {
        this.setState({ allowError: true })
    }

    hideError() {
        this.setState({ allowError: false })
    }

    handleChange(e) {
        this.props.setValue(e.currentTarget.value)
        if (this.props.onChange) this.props.onChange(e)
    }

    handleBlur() {
        if (this.props.isValid()) {
            this.hideError()
        } else if (!this.props.isPristine()) {
            this.showError()
        }
    }

    render() {
        const {
            className,
            getErrorMessage,
            getValue,
            id,
            name,
            placeholder,
            required,
            type
        } = this.props

        const errorMessage = getErrorMessage()
        const error = this.state.allowError && errorMessage

        return (
            <Fragment>
                <StyledInput
                    type={type}
                    name={name}
                    id={id}
                    required={required}
                    className={className}
                    placeholder={placeholder}
                    error={!!error}
                    value={getValue() || ''}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                />
                {error && <InputError>{errorMessage}</InputError>}
            </Fragment>
        )
    }
}

export default withFormsy(Input)
