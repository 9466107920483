import {
    FETCHING_PATIENTS,
    HYDRATE_PATIENTS,
    REMOVE_PATIENTS
} from '../actions/patient'

const intitialState = {
    isFetching: false
}

export default (state = intitialState, action) => {
    switch (action.type) {
        case FETCHING_PATIENTS:
            return action.payload
        case HYDRATE_PATIENTS:
            return action.payload
        case REMOVE_PATIENTS:
            return action.payload
        default:
            return state
    }
}
