export const fontWeights = {
    extraLight: 'Gotham 2r',
    light: 'Gotham 3r',
    book: 'Gotham 4r',
    medium: 'Gotham 5r',
    bold: 'Gotham 7r',
    black: 'Gotham 8r'
}

export const colors = {
    bw1: '#F6F6F5',
    bw2: '#EDEDEB',
    bw3: '#E3E3DE',
    bw4: '#D3D3D0',
    bw5: '#C5C5C1',
    bw6: '#B7B7B3',
    bw7: '#696967',
    bw8: '#525250',
    bw9: '#383837',
    bw10: '#2E2E2E',
    bw11: '#242424',
    bw12: '#121212',
    blue: '#435B91',
    orange: '#FF7113',
    lightRed: '#FFF6F6',
    red: '#E0B4B4',
    white: '#FFF',
    green: '#B9E88B'
}

export const styles = {
    colors: colors,
    fontWeights: fontWeights,
    maxWidth: '1280px',
    maxWidthSkinny: '800px',
    navHeight: '5rem',
    footerHeight: '2rem',
    baseFontColor: colors.bw9,
    backgroundColor: colors.bw2,
    borderColor: colors.bw5
}

export default styles
