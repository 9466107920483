import styled, { css } from 'styled-components'
import { styles } from '../../assets/styles/globals'

const StyledButton = styled.button`
    border-style: none;
    outline: none;
    display: ${props => (props.inline ? 'inline' : 'block')};
    width: ${props => (props.autoWidth ? 'auto' : '100%')};
    text-align: center;
    background: ${styles.colors.orange};
    padding: 0.9rem 1.5rem;
    color: ${styles.colors.white};
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &[disabled] {
        opacity: 0.5;
    }
`

const verticalAlignMiddle = css`
    vertical-align: middle;
`

export const TextAsButton = styled.span`
    ${verticalAlignMiddle};
    cursor: pointer;

    span {
        ${verticalAlignMiddle};
    }

    svg {
        ${verticalAlignMiddle};
        margin-right: 0.313rem;
    }
`

export default StyledButton
