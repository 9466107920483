import { injectGlobal } from 'styled-components'
import { styles } from '../assets/styles/globals'

import '../../node_modules/normalize.css/normalize.css'
import '../assets/styles/generic.reset.css'
import '../assets/styles/generic.fontfaces.css'

// eslint-disable-next-line no-unused-expressions
injectGlobal`
    html {
        font-size: 16px;
        line-height: 1.5;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        box-sizing: border-box;
        scroll-behavior: smooth;
    }

    body {
        // font-family: 'Raleway', sans-serif;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        height: 100%;
        color: ${styles.baseFontColor};
        background-color: ${styles.backgroundColor};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }
`
