import base64 from 'base-64'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import store from '../store'
import { authUser, unAuthUser } from '../actions/user'
import { removePatients } from '../actions/patient'
import { removeSurveys } from '../actions/surveys'

const setUserData = (token, creds) => {
    const tokenData = jwtDecode(token)
    const userData = {
        isAuthed: true,
        authTime: Date.now(),
        uid: tokenData.id.uid,
        name: `${tokenData.id.first} ${tokenData.id.last}`,
        email: creds.username,
        exp: tokenData.exp,
        perms: tokenData.prm,
        token: token,
        notifications: true // This probably doesn't belong here; just here for illustrative purposes
    }
    store.dispatch(authUser(userData))
}

export const handleLogin = (creds, errorCallback) => {
    let userCreds = `${creds.username}:${creds.password}`
    userCreds = base64.encode(userCreds)

    const request = new Request(`${process.env.REACT_APP_USER_API_URL}/@me/tokens`, {
        method: 'POST',
        headers: new Headers({
            Authorization: `Basic ${userCreds}`
        })
    })

    fetch(request)
        .then(resp => resp.json())
        .then((data) => {
            data.token ?
                setUserData(data.token, creds)
                :
                errorCallback()
        })
        .catch((error) => {
            errorCallback(error.message)
        })
}

const removeUserData = () => {
    store.dispatch(unAuthUser())
    store.dispatch(removePatients())
    store.dispatch(removeSurveys())
}

export const handleLogOut = () => {
    const user = store.getState().user
    axios.delete(`${process.env.REACT_APP_USER_API_URL}/${user.uid}/tokens`, {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    })
        .then(() => removeUserData())
        .catch(() => removeUserData())
}
