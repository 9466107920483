export const FETCHING_PATIENTS = 'patient/FETCHING_PATIENTS'
export const HYDRATE_PATIENTS = 'patient/HYDRATE_PATIENTS'
export const REMOVE_PATIENTS = 'patient/REMOVE_PATIENTS'

export const setFetchingPatients = () => ({
    type: FETCHING_PATIENTS,
    payload: {
        isFetching: true
    }
})

export const hydratePatients = patientData => ({
    type: HYDRATE_PATIENTS,
    payload: {
        isFetching: false,
        patientList: patientData,
        lastUpdate: Date.now()
    }
})

export const removePatients = () => ({
    type: REMOVE_PATIENTS,
    payload: {
        isFetching: false
    }
})
