import React from 'react'
import styled from 'styled-components'
import { styles } from '../assets/styles/globals'
import Container from '../components/containers'

const year = new Date().getFullYear()

const FooterEl = styled.footer`
    display: grid;
    align-content: center;
    background-color: ${styles.colors.white};
    text-align: center;
    font-size: 0.875rem;
`

const HeartWrapper = styled.span`
    color: ${styles.colors.red};
`

const Footer = () => (
    <FooterEl>
        <Container>
            &copy; {year} Jawbone Health &bull; Made in SF{' '}
            <HeartWrapper>&#10084;</HeartWrapper>
        </Container>
    </FooterEl>
)

export default Footer
