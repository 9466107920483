export const FETCHING_SURVEYS = 'surveys/FETCHING_SURVEYS'
export const HYDRATE_SURVEYS = 'surveys/HYDRATE_SURVEYS'
export const REMOVE_SURVEYS = 'surveys/REMOVE_SURVEYS'

export const setFetchingSurveys = () => ({
    type: FETCHING_SURVEYS,
    payload: {
        isFetching: true
    }
})

export const hydrateSurveys = surveyData => ({
    type: HYDRATE_SURVEYS,
    payload: {
        isFetching: false,
        surveyList: surveyData,
        lastUpdate: Date.now()
    }
})

export const removeSurveys = () => ({
    type: REMOVE_SURVEYS,
    payload: {
        isFetching: false
    }
})
