import {
    FETCHING_SURVEYS,
    HYDRATE_SURVEYS,
    REMOVE_SURVEYS
} from '../actions/surveys'

const intitialState = {
    isFetching: false
}

export default (state = intitialState, action) => {
    switch (action.type) {
        case FETCHING_SURVEYS:
            return action.payload
        case HYDRATE_SURVEYS:
            return action.payload
        case REMOVE_SURVEYS:
            return action.payload
        default:
            return state
    }
}
