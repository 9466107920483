import { createStore, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '../reducers'

const persistConfig = {
    key: 'root',
    storage: storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const enhancer = compose(
    window.devToolsExtension ? window.devToolsExtension() : f => f
)

const store = createStore(persistedReducer, {}, enhancer)

export const persistor = persistStore(store)
export default store
