import styled from 'styled-components'
import { styles } from '../../assets/styles/globals'

export const Li = styled.li`
    padding: 1rem;
    border-bottom: 1px solid ${styles.borderColor};
`

export const UnstyledLi = styled.li`
    padding: 1rem;
`

export default Li
