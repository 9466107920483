import axios from 'axios'
import faker from 'faker/locale/en'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import store from '../store'
import { setFetchingPatients, hydratePatients } from '../actions/patient'

const baseURL = process.env.REACT_APP_USER_API_URL
const timebanditBaseURL = process.env.REACT_APP_TIMEBANDIT_API_URL

export const findPatient = (patients, xid) => (
    patients ?
        patients.find(user => user.id === parseInt(xid, 0))
        : null
)

const formatPatientData = (patientList) => {
    const formattedPatientList = []
    let newPatient = {}
    patientList.map((patient) => {
        newPatient = patient
        newPatient.avatar = faker.image.avatar()
        formattedPatientList.push(newPatient)
        newPatient = {}
        return true
    })
    return formattedPatientList
}

export const getAllPatients = (user) => {
    store.dispatch(setFetchingPatients())
    axios.get(`${baseURL}/?type=patient&type=admin`, {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    })
        .then((patients) => {
            formatPatientData(patients.data)
            store.dispatch(hydratePatients(formatPatientData(patients.data)))
        })
        .catch(error => console.log(error)) // eslint-disable-line
}

export const checkGetAllPatients = (user, patientList) => {
    // add timestamp check later
    if (!patientList && user.isAuthed) {
        getAllPatients(user)
    }
}

export const getPatientBiometrics = (uid, token) => (
    axios.get(`${baseURL}/${uid}/biometrics`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
)

export const getPatientWeight = (
    uid,
    token,
    startDate = format(addDays(Date.now(), -7), 'yyyy-MM-dd'),
    endDate = format(Date.now(), 'yyyy-MM-dd')
) => (
    axios.get(`${timebanditBaseURL}/${uid}/weights?start_date=${startDate}&end_date=${endDate}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
)
