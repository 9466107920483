import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { mainNavData } from '../data/navs'
import Navigation from '../components/navigation'
import { handleLogOut } from '../services/signin'
import { checkGetAllPatients } from '../services/patient'
import { getAllSurveys } from '../services/surveys'

const { shape, instanceOf } = PropTypes

class NavigationContainer extends Component {
    static propTypes = {
        user: shape({}).isRequired,
        patient: shape({
            patientData: instanceOf(Array)
        })
    }

    static defaultProps = {
        patient: {
            patientData: []
        }
    }

    componentDidMount() {
        checkGetAllPatients(this.props.user, this.props.patient.patientList)
        getAllSurveys(this.props.user.token)
    }

    render() {
        return (
            this.props.patient.patientList ?
                <Navigation
                    user={this.props.user}
                    unAuthUser={handleLogOut}
                    navItems={mainNavData}
                    patients={this.props.patient.patientList}
                />
                : null
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    patient: state.patient
})

export default withRouter(connect(mapStateToProps)(NavigationContainer))
