import React, { Component, Fragment } from 'react'
import { arrayOf, bool, func, shape } from 'prop-types'
import styled from 'styled-components'
import Formsy from 'formsy-react'
import ReactModal from 'react-modal'
import ArrowForward from '@material-ui/icons/ArrowForward'
import Close from '@material-ui/icons/Close'

import { styles } from '../assets/styles/globals'
import { blockElement, modals, padded } from '../assets/styles/style-utils'
import { UnstyledUl } from './elements/ul'
import { UnstyledLi, Li } from './elements/li'
import Button from './elements/button'
import StyledLink from './elements/anchor'
import Input from './elements/input'
import { UnderlinedHeading } from '../components/elements/heading'

ReactModal.setAppElement('#root')

const PatientList = UnstyledUl.extend`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 0;
`

const UserLink = StyledLink.extend`
    display: grid;
    grid-template-columns: 1fr 2rem;
    grid-gap: 2rem;
`

const UpperSelection = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
    ${blockElement};
`

const PatientListItem = Li.extend`
    &:hover {
        border-bottom: 1px solid ${styles.colors.orange};
    }
`

const ViewAllWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;
`

const ArrowHolder = styled.div`
    display: grid;
    align-content: center;
    color: ${styles.colors.bw9};
`

const CloseButton = styled(Close)`
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
`

const Subheading = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 1rem;
`

const ModalWrap = styled.div`
    ${padded};
`

class PatientSearch extends Component {
    static propTypes = {
        patients: arrayOf(shape({})).isRequired,
        fetchNewPatients: func.isRequired,
        closePatientSearch: func.isRequired,
        visible: bool.isRequired
    }

    static defaultProps = {
        visible: false
    }

    constructor() {
        super()
        this.showAll = this.showAll.bind(this)
        this.searchForUsers = this.searchForUsers.bind(this)
    }

    state = {
        display: 'default'
    }

    showAll() {
        this.setState({ filteredPatients: this.props.patients })
    }

    searchForUsers(e) {
        const searchTerm = e.target.value.toUpperCase()
        const filteredPatients = this.props.patients.filter((patient) => {
            const name = `${patient.first} ${patient.last}`
            return name.toUpperCase().indexOf(searchTerm) >= 0
        })
        this.setState({ filteredPatients })
    }

    render() {
        return (
            <ReactModal
                isOpen={this.props.visible}
                contentLabel="Find a patient"
                onRequestClose={this.props.closePatientSearch}
                style={modals}
            >
                <ModalWrap>
                    <header>
                        <UnderlinedHeading>Find a patient</UnderlinedHeading>
                    </header>
                    <CloseButton onClick={this.props.closePatientSearch} />
                    <UpperSelection>
                        <Formsy>
                            <Input
                                type="search"
                                name="patient"
                                id="patient"
                                validations="isExisty"
                                placeholder="Enter a patient's name…"
                                onChange={this.searchForUsers}
                            />
                        </Formsy>
                        <ViewAllWrapper>
                            <Button onClick={this.showAll}>
                                View all patients
                            </Button>
                            <Button onClick={this.props.fetchNewPatients}>
                                Fetch new patients
                            </Button>
                        </ViewAllWrapper>
                    </UpperSelection>
                    {this.state.filteredPatients && (
                        <Fragment>
                            <Subheading>Search results:</Subheading>
                            <PatientList>
                                {this.state.filteredPatients.length ? (
                                    this.state.filteredPatients.map(user => (
                                        <PatientListItem key={user.id}>
                                            <UserLink
                                                to={`/patient/${user.id}`}
                                                onClick={
                                                    this.props
                                                        .closePatientSearch
                                                }
                                            >
                                                <span>
                                                    {user.first} {user.last}
                                                </span>
                                                <ArrowHolder>
                                                    <ArrowForward />
                                                </ArrowHolder>
                                            </UserLink>
                                        </PatientListItem>
                                    ))
                                ) : (
                                    <UnstyledLi>No results</UnstyledLi>
                                )}
                            </PatientList>
                        </Fragment>
                    )}
                </ModalWrap>
            </ReactModal>
        )
    }
}

export default PatientSearch
