import React, { Fragment, Component } from 'react'
import { bool, shape } from 'prop-types'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { styles } from '../assets/styles/globals'

import '../components/cssImports'

import asyncComponent from '../components/async'
import NavigationContainer from './navigation-container'
import FooterContainer from './footer-container'

const AsyncSignInContainer = asyncComponent(() => import('./signin-container'))
const AsyncDashboardContainer = asyncComponent(() =>
    import('./dashboard-container')
)
const AsyncPatientChartContainer = asyncComponent(() =>
    import('./patient-container')
)
const AsyncSettingsContainer = asyncComponent(() =>
    import('./settings-container')
)
const AsyncError404 = asyncComponent(() => import('../components/error-404'))

const MainWrapper = styled.div`
    height: 100vh;
    display: grid;
    grid-template-rows: ${styles.navHeight} 1fr ${styles.footerHeight};
    grid-template-areas:
        'header'
        'content'
        'footer';
`

class MainContainer extends Component {
    static propTypes = {
        user: shape({
            isAuthed: bool.isRequired
        }).isRequired
    }

    static defaultProps = {
        patient: {
            patientList: null
        }
    }

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <MainWrapper>
                {this.props.user.isAuthed ? (
                    <Router>
                        <Fragment>
                            <NavigationContainer />
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    component={AsyncDashboardContainer}
                                />
                                <Route
                                    exact
                                    path="/patient/:xid"
                                    component={AsyncPatientChartContainer}
                                />
                                <Route
                                    exact
                                    path="/settings"
                                    component={AsyncSettingsContainer}
                                />
                                <Route component={AsyncError404} />
                            </Switch>
                            <FooterContainer />
                        </Fragment>
                    </Router>
                ) : (
                    <Router>
                        <AsyncSignInContainer />
                    </Router>
                )}
            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(MainContainer)
