import styled from 'styled-components'
import { styles } from '../../assets/styles/globals'

const Heading = styled.h1`
    font-size: 2rem;
    margin-bottom: 2rem;
`

export const SubHeading = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 2rem;

    svg {
        margin-right: 0.5rem;
    }
`

export const UnderlinedHeading = Heading.extend`
    border-bottom: 3px solid ${styles.colors.orange};
    display: inline-block;
`

export default Heading
