import styled from 'styled-components'
import { styles } from '../assets/styles/globals'

const Container = styled.section`
    width: 100%;
    max-width: ${props =>
        (props.skinny ? styles.maxWidthSkinny : styles.maxWidth)};
    margin: 0 auto;
`

export const PaddedContainer = Container.extend`
    padding: 2rem 0;
`

export default Container
