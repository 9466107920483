import { css } from 'styled-components'
import { styles } from '../../assets/styles/globals'

const boxShadowString = '0 0.25rem 0.375rem 0 rgba(0, 0, 0, 0.2)'

const visuallyHidden = css`
    &:not(:focus):not(:active) {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;
        clip-path: inset(100%);
        clip: rect(0 0 0 0);
        overflow: hidden;
    }
`

export const padded = css`
    padding: 2rem;
`

export const boxShadow = css`
    box-shadow: ${boxShadowString};
`

// Note: The modal styles are defined such that they
// can be ingested by the modal component
export const modals = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        boxShadow: boxShadowString,
        padding: 0
    }
}

export const cleanInputStyles = css`
    border-width: 0;
    box-shadow: none;
    outline: none;
    background: ${props =>
        (props.error ? styles.colors.lightRed : styles.colors.white)};
    display: block;
    margin: 0;
    padding: 0.8rem 1.25rem;
    width: 100%;
    max-width: 100%;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    appearance: none; // not a standard property, but Normalize will prefix it
`

export const blockElement = css`
    &:not(:last-child) {
        margin-bottom: 2rem;
    }
`

export default visuallyHidden
