export const mainNavData = [
    {
        title: 'Dashboard',
        href: '/',
        id: 'dashboard',
        exact: true
    }
]

export default mainNavData
