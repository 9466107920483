import { combineReducers } from 'redux'
import user from './user'
import patient from './patient'
import surveys from './surveys'

const rootReducer = combineReducers({
    user,
    patient,
    surveys
})

export default rootReducer
