import axios from 'axios'

import store from '../store'
import { setFetchingSurveys, hydrateSurveys } from '../actions/surveys'
// leave this: we need it to fine tune survey output
// import { surveyData } from '../data/surveys'

const baseURL = process.env.REACT_APP_FACTUARY

export const getAllSurveys = (token) => {
    store.dispatch(setFetchingSurveys())
    axios({
        url: `${baseURL}/surveys`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(res => store.dispatch(hydrateSurveys(res.data.surveys)))
}

// leave this: we need it to fine tune survey output
// export const getPatientSurveys = () => {
//     return new Promise((resolve) => {
//         const res = {
//             data: surveyData
//         }
//         resolve(res)
//     })
// }

export const getPatientSurveys = (user, patientID) => (
    axios({
        url: `${baseURL}/users/${patientID}/surveys?skip_conditions_check=true?`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    })
)

export const assignSurvey = (user, formData) => (
    axios({
        url: `${baseURL}/users/${formData.patient_id}/surveys`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${user.token}`
        },
        data: {
            surveys: [
                formData.survey_select
            ]
        }
    })
)
